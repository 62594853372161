<template>
  <div>
    <modal
      name="modal-perfect-store-objectives"
      :width="`90%`"
      :height="`auto`"
      scrollable
    >
      <v-snackbar v-model="snackbar" :color="snackbar_class" elevation="24">
        {{ text_snackbar }}
        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="snackbar = false">
            Close
          </v-btn>
        </template>
      </v-snackbar>
      <v-form ref="form" lazy-validation>
        <v-card>
          <v-card-text class="objective_content">
            <span class="text-h5">Perfect Store Objectives</span>
          </v-card-text>

          <v-card-text>
            <v-row>
              <v-col cols="12" sm="12" md="12" class="text-center">
                <v-btn
                  v-if="importExcelPerfectStoreObjectivesTemplatePathName"
                  class="mb-2 ml-2"
                  color="blue darken-1"
                  @click="
                    downloadExcelTemplateGlobal(
                      importExcelPerfectStoreObjectivesTemplatePathName
                    )
                  "
                >
                  <b-icon icon="download" aria-hidden="true"></b-icon> Plantilla
                  Import
                </v-btn>
                <v-btn
                  color="success"
                  dark
                  class="mb-2 ml-2"
                  :loading="isSelectingImport"
                  @click="onUploadExcel"
                >
                  <b-icon icon="file-excel-fill" aria-hidden="true"></b-icon>
                  {{ importButtonText }}
                </v-btn>
                <input
                  ref="uploader_file"
                  class="d-none"
                  type="file"
                  accept=".xls,.xlsx"
                  @change="onFileChangedChainChannelPerBrand"
                />
                <v-btn
                  color="warning"
                  dark
                  class="mb-2 ml-2"
                  @click="downloadexcel"
                >
                  <b-icon icon="file-excel-fill" aria-hidden="true"></b-icon>
                  Export
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-data-table
                  :headers="columns"
                  :items="tableData"
                  :items-per-page="10"
                  :footer-props="{
                    'items-per-page-options': [10, 50, 100],
                  }"
                  :options.sync="options"
                  class="elevation-1"
                >
                  <template
                    v-for="(col, i) in filters"
                    v-slot:[`header.${i}`]="{ header }"
                  >
                    <div
                      style="display: inline-block; padding: 16px 0;"
                      v-bind:key="i"
                    >
                      <span>{{ header.text }}</span>
                    </div>
                    <div
                      style="float: right; margin-top: 8px"
                      v-bind:key="`A` + i"
                    >
                      <v-menu
                        :close-on-content-click="false"
                        :nudge-width="200"
                        offset-y
                        transition="slide-y-transition"
                        left
                        fixed
                        style="position: absolute; right: 0"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn color="indigo" icon v-bind="attrs" v-on="on">
                            <v-icon
                              small
                              :color="
                                activeFilters[header.value] &&
                                activeFilters[header.value].length <
                                  filters[header.value].length
                                  ? 'red'
                                  : 'default'
                              "
                            >
                              mdi-filter-variant
                            </v-icon>
                          </v-btn>
                        </template>
                        <v-list flat dense class="pa-0">
                          <v-list-item-group
                            multiple
                            v-model="activeFilters[header.value]"
                            class="py-2"
                          >
                            <div class="search_input">
                              <v-text-field
                                hide-details
                                v-model="searchString[header.value]"
                                placeholder="Search ..."
                                class="pl-2 pr-2"
                                @input="searchItem(header.value)"
                              ></v-text-field>
                            </div>
                            <template
                              v-for="(item, j) in filters[header.value]"
                            >
                              <v-list-item
                                v-bind:key="`A${j}`"
                                :value="item"
                                :ripple="false"
                              >
                                <template v-slot:default="{ active, toggle }">
                                  <v-list-item-action>
                                    <v-checkbox
                                      :input-value="active"
                                      :true-value="item"
                                      @click="toggle"
                                      color="primary"
                                      :ripple="false"
                                      dense
                                    ></v-checkbox>
                                  </v-list-item-action>
                                  <v-list-item-content>
                                    <v-list-item-title
                                      v-text="item"
                                    ></v-list-item-title>
                                  </v-list-item-content>
                                </template>
                              </v-list-item>
                            </template>
                          </v-list-item-group>
                          <v-row no-gutters>
                            <v-col cols="6">
                              <v-btn
                                text
                                block
                                @click="toggleAll(header.value)"
                                color="success"
                                >{{ $t("toggleall") }}</v-btn
                              >
                            </v-col>
                            <v-col cols="6">
                              <v-btn
                                text
                                block
                                @click="clearAll(header.value)"
                                color="warning"
                                >{{ $t("clearall") }}</v-btn
                              >
                            </v-col>
                          </v-row>
                        </v-list>
                      </v-menu>
                    </div>
                  </template>
                  <template v-slot:item.objective="{ item }">
                    <v-text-field
                      v-model="editedItem.objective"
                      type="number"
                      :hide-details="true"
                      dense
                      v-if="item.id === editedItem.id"
                    ></v-text-field>
                    <span v-else>{{ item.objective }}</span>
                  </template>
                  <template v-slot:item.bpo_value_dn="{ item }">
                    <v-text-field
                      v-model="editedItem.bpo_value_dn"
                      type="number"
                      :hide-details="true"
                      dense
                      v-if="item.id === editedItem.id"
                    ></v-text-field>
                    <span v-else>{{ item.bpo_value_dn }}</span>
                  </template>
                  <template v-slot:item.bpo_value_dp="{ item }">
                    <v-text-field
                      v-model="editedItem.bpo_value_dp"
                      type="number"
                      :hide-details="true"
                      dense
                      v-if="item.id === editedItem.id"
                    ></v-text-field>
                    <span v-else>{{ item.bpo_value_dp }}</span>
                  </template>
                  <template v-slot:item.bpoSalesLabel="{ item }">
                    <v-autocomplete
                      v-model="editedItem.bpoSales"
                      :items="bpoSalesTypeList"
                      item-value="value"
                      item-text="label"
                      filled
                      :hide-details="true"
                      dense
                      v-if="item.id === editedItem.id"
                    ></v-autocomplete>
                    <span v-else>{{ item.bpoSalesLabel }}</span>
                  </template>
                  <template v-slot:item.actions="{ item }">
                    <div v-if="item.id === editedItem.id">
                      <v-icon color="red" class="mr-3" @click="close">
                        mdi-window-close
                      </v-icon>
                      <v-icon color="green" @click="save">
                        mdi-content-save
                      </v-icon>
                    </div>
                    <div v-else>
                      <v-icon
                        color="green"
                        class="mr-3"
                        @click="editItem(item)"
                      >
                        mdi-pencil
                      </v-icon>
                    </div>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click.stop="hideModal()">
              {{ $t("cancel") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </modal>
  </div>
</template>

<script>
import ApiService from "@/services/apiService";
import { logInfo, logError } from "@/utils";
import _ from "lodash";
import download from "js-file-download";

export default {
  name: "DialogPerfectStoreObjectives",
  props: [
    "importExcelPerfectStoreObjectivesTemplatePathName",
    "row",
    "update_table",
    "bpoSalesTypes",
    "bpoSalesTypeList",
  ],
  components: {},
  data: function() {
    return {
      /****** IMPORT && EXPORT ******/
      isSelectingImport: false,
      selectedFile: null,
      defaultImportbuttonText: "Import",

      /********** snackbar **********/
      snackbar: false,
      text_snackbar: "The imported file is invalid.",
      vertical: true,
      snackbar_class: "deep-purple",

      editedIndex: -1,
      editedItem: {
        id: 0,
        objective: "",
        bpo_value_dn: "",
        bpo_value_dp: "",
        bpoSales: "",
      },
      defaultItem: {
        id: 0,
        objective: "",
        bpo_value_dn: "",
        bpo_value_dp: "",
        bpoSales: "",
      },
      current_rows: null,
      tableData: [],
      tempdata: [],
      options: {},
      loading: false,
      filterCriteria: {},
      activeFilters: {},
      filters: {
        posId: [],
        brandId: [],
        posName: [],
        objective: [],
        bpo_value_dn: [],
        bpo_value_dp: [],
        bpoSalesLabel: [],
      },
      initFilterList: {
        posId: [],
        brandId: [],
        posName: [],
        objective: [],
        bpo_value_dn: [],
        bpo_value_dp: [],
        bpoSalesLabel: [],
      },
      searchString: {
        posId: "",
        brandId: "",
        posName: "",
        objective: "",
        bpo_value_dn: "",
        bpo_value_dp: "",
        bpoSalesLabel: "",
      },
    };
  },
  watch: {
    async row(newVal, oldVal) {
      if (newVal) {
        this.init(newVal);
      }
    },
    options: {
      handler() {
        this.sortChange();
      },
      deep: true,
    },
  },
  computed: {
    formTitle() {
      return this.$t(this.category ? this.category : "chain");
    },
    importButtonText() {
      return this.isSelectingImport
        ? "Uploading..."
        : this.defaultImportbuttonText;
    },
    columns() {
      return [
        {
          text: `Id POS`,
          value: "posId",
          width: "250px",
          filter: (value) => {
            return this.activeFilters.posId
              ? this.activeFilters.posId.includes(value)
              : true;
          },
        },
        {
          text: `POS ${this.$t("Name")}`,
          value: "posName",
          width: "300px",
          filter: (value) => {
            return this.activeFilters.posName
              ? this.activeFilters.posName.includes(value)
              : true;
          },
        },
        {
          text: this.$t("objective"),
          value: "objective",
          width: "150px",
          filter: (value) => {
            return this.activeFilters.objective
              ? this.activeFilters.objective.includes(value)
              : true;
          },
        },
        {
          text: `DN`,
          value: "bpo_value_dn",
          width: "150px",
          filter: (value) => {
            return this.activeFilters.bpo_value_dn
              ? this.activeFilters.bpo_value_dn.includes(value)
              : true;
          },
        },
        {
          text: `DP`,
          value: "bpo_value_dp",
          width: "150px",
          filter: (value) => {
            return this.activeFilters.bpo_value_dp
              ? this.activeFilters.bpo_value_dp.includes(value)
              : true;
          },
        },
        {
          text: this.$t("brands.Sales"),
          value: "bpoSalesLabel",
          width: "150px",
          filter: (value) => {
            return this.activeFilters.bpoSalesLabel
              ? this.activeFilters.bpoSalesLabel.includes(value)
              : true;
          },
        },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
          width: "100px",
        },
      ];
    },
  },
  methods: {
    /****** MODAL CONFIGURATION ******/
    showModal(row) {
      this.init(row);
      this.$modal.show("modal-perfect-store-objectives");
    },
    hideModal() {
      this.$modal.hide("modal-perfect-store-objectives");
    },

    /****** IMPORT && EXPORT ******/
    onUploadExcel() {
      this.isSelectingImport = true;
      window.addEventListener(
        "focus",
        () => {
          this.isSelectingImport = false;
        },
        { once: false }
      );
      this.$refs.uploader_file.click();
    },
    async onFileChangedChainChannelPerBrand(e) {
      this.selectedFile = e.target.files[0];
      if (window.confirm(`Are you sure you want to upload this excel-file?`)) {
        this.isSelectingImport = true;
        let data = new FormData();
        data.append("file", this.selectedFile);
        this.$refs.uploader_file.value = "";
        try {
          const response = await ApiService.post(
            `brands/uploadexcel_perfectstoreobjectives/${this.row.id}`,
            data,
            {
              headers: {
                "Content-Type": `multipart/form-data;`,
              },
            }
          );
          const {
            success,
            insertedRowCount,
            failedRows,
            invalidFile,
            message,
          } = response;
          this.snackbar_class = "deep-purple";
          if (success && insertedRowCount > 0) {
            this.text_snackbar = `${insertedRowCount} row${
              insertedRowCount > 1 ? "s" : ""
            } imported successfully`;
            this.snackbar = true;
            await this.update_table();
          } else if (failedRows) {
            this.text_snackbar = `Inserted count: ${response.insertedRowCount}, Failed count: ${response.failedRows.length}`;
            this.snackbar = true;
            this.exportCSVfileGlobal(response.titles, response.failedRows);
            await this.update_table();
          } else if (!invalidFile && message) {
            this.text_snackbar = message;
            this.snackbar_class = "red";
            this.snackbar = true;
          }
          this.isSelectingImport = false;
        } catch (error) {
          console.log(error);
          this.isSelectingImport = false;
        }
      }
    },
    async downloadexcel() {
      let fetch_url = `brands/downloadexcel_perfectstoreobjectives/${this.row.id}`;
      const response = await ApiService.get(fetch_url, {
        responseType: "blob",
      });
      download(response, "perfectstoreobjectives.xlsx");
    },

    /********** Table options **********/
    editItem(item) {
      this.editedIndex = this.tableData.indexOf(item);
      this.editedItem = Object.assign({}, item);
    },
    close() {
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedIndex = -1;
    },
    async save() {
      if (this.editedIndex > -1) {
        let postData = {
          id: this.editedItem.id,
          objective: this.editedItem.objective,
          bpo_value_dn: this.editedItem.bpo_value_dn,
          bpo_value_dp: this.editedItem.bpo_value_dp,
          bpoSales: this.editedItem.bpoSales,
        };
        try {
          await ApiService.post(
            `brands/update_perfectstoreobjective`,
            postData
          );
          logInfo("Éxito !");
          await this.update_table();
          this.close();
        } catch (error) {
          logError("Error occured !");
        }
      }
    },
    initFilters() {
      let keys = Object.keys(this.initFilterList);
      for (const key of keys) {
        this.searchString[key] = "";
        this.initFilterList[key] = this.tableData
          .map((d) => {
            return d[key];
          })
          .filter((value, index, self) => {
            return self.indexOf(value) === index;
          });
      }
      this.filters = Object.assign({}, this.initFilterList);
      this.activeFilters = Object.assign({}, this.filters);
    },
    searchItem(col) {
      this.changeStr(col, this.searchString[col]);
    },
    changeStr(col, search_str) {
      if (search_str) {
        this.filters[col] = this.initFilterList[col].filter((item) =>
          item.toLowerCase().includes(search_str.toLowerCase())
        );
      } else {
        this.filters[col] = this.initFilterList[col];
      }
      this.activeFilters = Object.assign({}, this.filters);
    },
    toggleAll(col) {
      this.activeFilters[col] = this.tableData
        .map((d) => {
          return d[col];
        })
        .filter((value, index, self) => {
          return self.indexOf(value) === index;
        });
      this.searchString[col] = null;
      this.changeStr(col, null);
    },
    clearAll(col) {
      this.activeFilters[col] = [];
    },
    sortChange() {
      let { sortBy, sortDesc } = this.options;
      if (sortBy && sortBy.length > 0) {
        sortBy = sortBy[0];
        sortDesc = sortDesc[0];
        if (sortDesc) {
          this.tempdata = _.sortBy(this.tempdata, sortBy).reverse();
        } else {
          this.tempdata = _.sortBy(this.tempdata, sortBy);
        }
        this.tableData = this.tempdata;
      }
    },

    /****** INITIALIZE ******/
    async init(row) {
      this.current_rows = this.row?.brand_perfectstore_objectives;
      if (this.current_rows) {
        this.current_rows = this.current_rows.map((item) => {
          let push_item = { ...item };
          push_item["posName"] = item?.po?.name;
          push_item["brandName"] = item?.brand?.name;
          return push_item;
        });
      } else {
        this.current_rows = [];
      }
      this.tableData = this.current_rows;
      this.tempdata = this.current_rows;
      this.initFilters();
    },
  },
  async updated() {},
  async mounted() {},
};
</script>

<style></style>
