<template>
  <div>
    <modal name="modal-add-clients" :width="`90%`" :height="`auto`" scrollable>
      <v-snackbar v-model="snackbar" :color="snackbar_class" elevation="24">
        {{ text_snackbar }}
        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="snackbar = false">
            Close
          </v-btn>
        </template>
      </v-snackbar>
      <v-form ref="form" lazy-validation>
        <v-card>
          <v-card-text class="objective_content">
            <span class="text-h5">{{ formTitle }}</span>
          </v-card-text>

          <v-card-text>
            <v-row>
              <v-col cols="12" sm="12" md="12" class="text-center">
                <v-btn
                  v-if="importExcelTemplatePathName"
                  class="mb-2 ml-2"
                  color="blue darken-1"
                  @click="downloadExcelTemplate(importExcelTemplatePathName)"
                >
                  <b-icon icon="download" aria-hidden="true"></b-icon> Plantilla
                  Import
                </v-btn>
                <v-btn
                  color="success"
                  dark
                  class="mb-2 ml-2"
                  :loading="isSelectingImport"
                  @click="onUploadExcel"
                >
                  <b-icon icon="file-excel-fill" aria-hidden="true"></b-icon>
                  {{ importButtonText }}
                </v-btn>
                <input
                  ref="uploader_file"
                  class="d-none"
                  type="file"
                  accept=".xls,.xlsx"
                  @change="onFileChangedChainChannelPerBrand"
                />
                <v-btn
                  color="warning"
                  dark
                  class="mb-2 ml-2"
                  @click="downloadexcel"
                >
                  <b-icon icon="file-excel-fill" aria-hidden="true"></b-icon>
                  Export
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <ve-table
                  id="datatable-chainchannelperbrands"
                  fixed-header
                  max-height="calc(100vh - 330px)"
                  :columns="
                    category === 'chain' ? chain_columns : channel_columns
                  "
                  :table-data="tableData"
                  :border-around="true"
                  :border-x="true"
                  :border-y="true"
                  :scroll-width="1000"
                  :sort-option="sortOption"
                  :cell-style-option="cellStyleOption"
                />
                <div v-show="showEmpty" class="empty-data">Data Empty.</div>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click.stop="hideModal()">
              {{ $t("cancel") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </modal>
  </div>
</template>

<script>
import ApiService from "@/services/apiService";
import { logInfo, logError } from "@/utils";
import _ from "lodash";
import download from "js-file-download";

export default {
  name: "DialogChainsOrChannels",
  components: {},
  data: function() {
    return {
      category: "chain",
      row: null,

      /****** IMPORT && EXPORT ******/
      isSelectingImport: false,
      selectedFile: null,
      defaultImportbuttonText: "Import",
      importExcelTemplatePathName: null,

      /********** snackbar **********/
      snackbar: false,
      text_snackbar: "The imported file is invalid.",
      vertical: true,
      snackbar_class: "deep-purple",

      /********** vue-easytable options **********/
      loadingInstance: null,
      sorting: null,
      sortOption: {
        sortChange: (params) => {
          this.sortChange(params);
        },
      },
      pageIndex: 1,
      pageSize: 100,
      pageOptions: [10, 50, 100],
      cellStyleOption: {
        headerCellClass: ({ column, rowIndex }) => {
          if (this.filterable_col_list.includes(column.field)) {
            return "filterable-column " + column.field;
          }
        },
      },
      showEmpty: false,
      filterable_col_list: [
        "brand",
        "description",
        "startDate",
        "endDate",
        "status",
      ],
      filterCriteria: {
        brand: {},
        description: {},
        startDate: {},
        endDate: {},
        status: {},
      },
      filterCriteriaFetchUrl: `pos/getfilterlist`,
      tableData: [],
    };
  },
  watch: {},
  computed: {
    formTitle() {
      return this.$t(this.category ? this.category : "chain");
    },
    importButtonText() {
      return this.isSelectingImport
        ? "Uploading..."
        : this.defaultImportbuttonText;
    },
    searchboxFetchUrl() {
      return `${this.filterCriteriaFetchUrl}/${this.category}`;
    },
    col_idpos() {
      return {
        key: "idPOS",
        title: `IdPOS`,
        field: "idPOS",
        align: "left",
        // sortBy: "",
        width: 150,
        fixed: "left",
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span class="text-bold">{row.idPOS}</span>;
        },
      };
    },
    col_status() {
      return {
        key: "status",
        title: this.$t("status"),
        field: "status",
        align: "left",
        // sortBy: "",
        width: 150,
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span class="text-bold">{row.status}</span>;
        },
      };
    },
    col_brandName() {
      return {
        key: "brandName",
        title: this.$t("brands.brand"),
        field: "brandName",
        align: "left",
        // sortBy: "",
        width: 150,
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span class="text-bold">{row.brandName}</span>;
        },
      };
    },
    col_defaultChain() {
      return {
        key: "defaultChain",
        title: `${this.$t("default")} ${this.$t("chain")}`,
        field: "defaultChain",
        align: "left",
        // sortBy: "",
        width: 150,
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span class="text-bold">{row.defaultChainName}</span>;
        },
      };
    },
    col_defaultSubChain() {
      return {
        key: "defaultSubChain",
        title: `${this.$t("default")} Sub ${this.$t("chain")}`,
        field: "defaultSubChain",
        align: "left",
        // sortBy: "",
        width: 150,
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span class="text-bold">{row.defaultSubChainName}</span>;
        },
      };
    },
    col_defaultSub2Chain() {
      return {
        key: "defaultSub2Chain",
        title: `${this.$t("default")} Sub-Sub ${this.$t("chain")}`,
        field: "defaultSub2Chain",
        align: "left",
        // sortBy: "",
        width: 150,
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span class="text-bold">{row.defaultSub2ChainName}</span>;
        },
      };
    },
    col_chain() {
      return {
        key: "chain",
        title: this.$t("chain"),
        field: "chain",
        align: "left",
        // sortBy: "",
        width: 150,
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span class="text-bold">{row.chainName}</span>;
        },
      };
    },
    col_subChain() {
      return {
        key: "subChain",
        title: `Sub ${this.$t("chain")}`,
        field: "subChain",
        align: "left",
        // sortBy: "",
        width: 150,
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span class="text-bold">{row.subChainName}</span>;
        },
      };
    },
    col_sub2Chain() {
      return {
        key: "sub2Chain",
        title: `Sub-Sub ${this.$t("chain")}`,
        field: "sub2Chain",
        align: "left",
        // sortBy: "",
        width: 150,
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span class="text-bold">{row.sub2ChainName}</span>;
        },
      };
    },
    col_defaultChannel() {
      return {
        key: "defaultChannel",
        title: `${this.$t("default")} ${this.$t("channel")}`,
        field: "defaultChannel",
        align: "left",
        // sortBy: "",
        width: 150,
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span class="text-bold">{row.defaultChannelName}</span>;
        },
      };
    },
    col_defaultSubChannel() {
      return {
        key: "defaultSubChannel",
        title: `${this.$t("default")} Sub ${this.$t("channel")}`,
        field: "defaultSubChannel",
        align: "left",
        // sortBy: "",
        width: 150,
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span class="text-bold">{row.defaultSubChannelName}</span>;
        },
      };
    },
    col_defaultSub2Channel() {
      return {
        key: "defaultSub2Channel",
        title: `${this.$t("default")} Sub-Sub ${this.$t("channel")}`,
        field: "defaultSub2Channel",
        align: "left",
        // sortBy: "",
        width: 150,
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span class="text-bold">{row.defaultSub2ChannelName}</span>;
        },
      };
    },
    col_channel() {
      return {
        key: "channel",
        title: this.$t("channel"),
        field: "channel",
        align: "left",
        // sortBy: "",
        width: 150,
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span class="text-bold">{row.channelName}</span>;
        },
      };
    },
    col_subChannel() {
      return {
        key: "subChannel",
        title: `Sub ${this.$t("channel")}`,
        field: "subChannel",
        align: "left",
        // sortBy: "",
        width: 150,
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span class="text-bold">{row.subChannelName}</span>;
        },
      };
    },
    col_sub2Channel() {
      return {
        key: "sub2Channel",
        title: `Sub-Sub ${this.$t("channel")}`,
        field: "sub2Channel",
        align: "left",
        // sortBy: "",
        width: 150,
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span class="text-bold">{row.sub2ChannelName}</span>;
        },
      };
    },
    chain_columns() {
      return [
        this.col_idpos,
        this.col_status,
        this.col_defaultChain,
        this.col_defaultSubChain,
        this.col_defaultSub2Chain,
        this.col_brandName,
        this.col_chain,
        this.col_subChain,
        this.col_sub2Chain,
      ];
    },
    channel_columns() {
      return [
        this.col_idpos,
        this.col_status,
        this.col_defaultChannel,
        this.col_defaultSubChannel,
        this.col_defaultSub2Channel,
        this.col_brandName,
        this.col_channel,
        this.col_subChannel,
        this.col_sub2Channel,
      ];
    },
  },
  methods: {
    /****** MODAL CONFIGURATION ******/
    showModal(row, category) {
      this.init(row, category);
      this.$modal.show("modal-add-clients");
    },
    hideModal() {
      this.$modal.hide("modal-add-clients");
    },

    /****** IMPORT && EXPORT ******/
    onUploadExcel() {
      this.isSelectingImport = true;
      window.addEventListener(
        "focus",
        () => {
          this.isSelectingImport = false;
        },
        { once: false }
      );
      this.$refs.uploader_file.click();
    },
    async onFileChangedChainChannelPerBrand(e) {
      this.selectedFile = e.target.files[0];
      if (window.confirm(`Are you sure you want to upload this excel-file?`)) {
        this.isSelectingImport = true;
        let data = new FormData();
        data.append("file", this.selectedFile);
        this.$refs.uploader_file.value = "";
        try {
          const response = await ApiService.post(
            `brands/chainchannel/uploadexcel/${this.category}/${this.row.id}`,
            data,
            {
              headers: {
                "Content-Type": `multipart/form-data;`,
              },
            }
          );
          const {
            success,
            insertedRowCount,
            failedRows,
            invalidFile,
            message,
          } = response;
          this.snackbar_class = "deep-purple";
          if (success && insertedRowCount > 0) {
            this.text_snackbar = `${insertedRowCount} row${
              insertedRowCount > 1 ? "s" : ""
            } imported successfully`;
            this.snackbar = true;
            await this.getDataFromApi();
          } else if (failedRows) {
            this.text_snackbar = `Inserted count: ${response.insertedRowCount}, Failed count: ${response.failedRows.length}`;
            this.snackbar = true;
            this.exportCSVfileGlobal(response.titles, response.failedRows);
            await this.getDataFromApi();
          } else if (!invalidFile && message) {
            this.text_snackbar = message;
            this.snackbar_class = "red";
            this.snackbar = true;
          }
          this.isSelectingImport = false;
        } catch (error) {
          console.log(error);
          this.isSelectingImport = false;
        }
      }
    },
    async downloadexcel() {
      let fetch_url = `brands/chainchannel/downloadexcel/${this.category}/${this.row.id}`;
      const response = await ApiService.get(fetch_url, {
        responseType: "blob",
      });
      download(response, "data.xlsx");
    },
    // async downloadExcelTemplate(downloadFile) {
    //   let downloadfile = ApiService.getExcelTemplateFileUri(downloadFile);
    //   try {
    //     const response = await ApiService.get(downloadfile, {
    //       responseType: "arraybuffer",
    //     });
    //     download(response, downloadFile);
    //   } catch (error) {
    //     // this.close();
    //   }
    // },
    async downloadExcelTemplate(downloadFile) {
      let fetch_url = `brands/chainchannel/downloadexceltemplate/${this.category}/${this.row.id}`;
      const response = await ApiService.get(fetch_url, {
        responseType: "blob",
      });
      download(response, "data.xlsx");
    },

    /********** vue-easytable options **********/
    pageNumberChange(pageIndex) {
      this.pageIndex = pageIndex;
      this.getDataFromApi();
    },
    pageSizeChange(pageSize) {
      this.pageIndex = 1;
      this.pageSize = pageSize;
      this.getDataFromApi();
    },
    sortChange(params) {
      this.sorting = params;
      this.getDataFromApi();
    },
    show() {
      this.loadingInstance = this.$veLoading({
        target: document.querySelector("#datatable-chainchannelperbrands"),
        name: "flow", // gird, flow, wave, bounce,
      });
      this.loadingInstance.show();
    },
    close() {
      this.loadingInstance.close();
    },
    searchConfirm(closeFn) {
      closeFn();
      this.getDataFromApi();
    },
    searchCancel(closeFn, cancelFilterKey) {
      closeFn();
      this.filterCriteria[cancelFilterKey] = {};
      this.getDataFromApi();
    },
    getQueryParams() {
      let query_params_string = "";
      let query_params = [];
      query_params.push("page=" + this.pageIndex);
      query_params.push("itemsPerPage=" + this.pageSize);
      if (this.sorting !== null) {
        for (const [key, value] of Object.entries(this.sorting)) {
          if (value !== null && value !== "") {
            query_params.push("sortby=" + key);
            query_params.push(
              "sortdesc=" + (value === "desc" ? "true" : "false")
            );
          }
        }
      }

      let filterModel = [];
      for (const [key, value] of Object.entries(this.filterCriteria)) {
        if (value !== "" && value !== null && Object.keys(value).length > 0) {
          filterModel.push({
            columnField: key,
            filterValue: value,
          });
        }
      }
      console.log(filterModel);
      let filter_columns = document.querySelectorAll(
        "#datatable-chainchannelperbrands .filterable-column"
      );
      for (let i = 0; i < filter_columns.length; i++) {
        filter_columns[i].classList.remove("filter_active");
      }
      if (filterModel.length > 0) {
        filterModel.map((filter_item) => {
          for (let i = 0; i < filter_columns.length; i++) {
            if (filter_columns[i].classList.contains(filter_item.columnField)) {
              filter_columns[i].classList.add("filter_active");
            }
          }
          return filter_item;
        });
      }

      query_params_string += query_params.join("&");
      if (filterModel.length > 0) {
        query_params_string +=
          "&filterModel=" + encodeURIComponent(JSON.stringify(filterModel));
      }
      return query_params_string;
    },
    async getDataFromApi() {
      this.show();
      try {
        let query_params_string = this.getQueryParams();
        let fetch_url = `brands/getchainchannel/${this.category}/${this.row.id}`;
        if (query_params_string !== "") {
          fetch_url += "?" + query_params_string;
        }
        const resp = await ApiService.get(fetch_url);
        this.tableData = resp.data;
        this.showEmpty = this.tableData.length === 0;
        this.importExcelTemplatePathName = resp.importExcelTemplatePathName;
        this.close();
      } catch (error) {
        this.close();
      }
    },

    /****** INITIALIZE ******/
    async init(row, category) {
      this.row = row;
      this.category = category;
      console.log("XXXXXXXXX - ", this.row, this.category);
      await this.getDataFromApi();
    },
  },
  async updated() {},
  async mounted() {},
};
</script>

<style></style>
