import { get } from "lodash";
import { generateUUID } from "@/utils";

export const QUESTION_TYPES = {
  PHOTO: "photo",
  STRING: "string",
  LONGTEXT: "longtext",

  BOOL: "bool",
  RADIO: "radio",
  CHECKBOX: "checkbox",
  SELECT: "select",
  ACTION: "action",

  DATE: "date",
  MONEY: "money",
  DECIMAL: "decimal",
  NUMBER: "number"
};

export const OP_STRING = {
  igual: "eq",
  diferente: "ne"
};

export const OP_BOOL = {
  igual: "eq",
  diferente: "ne"
};

export const OP_NUMBER = {
  "=": "eq",
  "<": "lt",
  "<=": "le",
  ">": "gt",
  ">=": "ge"
};

export const CALC_MULTIPLER = {
  MULTIPLY: "X",
  DIVIDE: "/"
};

export const CALC_OPERATION = {
  SUM: "Sum",
  // SUBTRACT: "Subtract",
  // MULTIPLY: "Multiply",
  // DIVIDE: "Divide",
  COUNT: "Count",
  AVERAGE: "Average"
};

export const CALC_INTEGRATION_OP = {
  SUM: "Sum",
  SUBTRACT: "Subtract",
  MULTIPLY: "Multiply",
  DIVIDE: "Divide"
};

export const CALC_OPERATE_SYMBOLS = {
  SUM: "+",
  SUBTRACT: "-",
  MULTIPLY: "*",
  DIVIDE: "/"
};

export class ComponentModel {
  constructor(body) {
    // primary props
    this.id = get(body, "id");
    this.surveyId = get(body, "surveyId");
    this.name = get(body, "name");
    this.uuid = get(body, "uuid", generateUUID());
    this.hideLabel = get(body, "hideLabel");
    this.hide = get(body, "hide", false);
    this.activeDateFrom = get(body, "activeDateFrom");
    this.activeDateTo = get(body, "activeDateTo");
    this.options = get(body, "options", {});
    this.repeats = get(body, "repeats");
    this.repeatsUnit = get(body, "repeatsUnit");
    this.repeatsValue = get(body, "repeatsValue");
    this.conditionals = get(body, "conditionals", []);

    //join props
    this.survey_questions = get(body, "survey_questions", []);
    this.products = get(body, "products", []);
    this.image = get(body, "image");

    //extended props
    this.assignType = get(body, "assignType") || "PRODUCT";
    this.component_options = get(body, "component_options") || null;
    this.sub_brand_ids = get(body, "sub_brand_ids") || [];
    this.sub2_brand_ids = get(body, "sub2_brand_ids") || [];
    this.main_family_ids = get(body, "main_family_ids") || [];
    this.sub_family_ids = get(body, "sub_family_ids") || [];
    this.sub2_family_ids = get(body, "sub2_family_ids") || [];
  }
}

export class QuestionModel {
  constructor(body) {
    // primary props
    this.id = get(body, "id");
    this.name = get(body, "name");
    this.uuid = get(body, "uuid", generateUUID());
    this.hideLabel = get(body, "hideLabel");
    this.hide = get(body, "hide", false);
    this.activeDateFrom = get(body, "activeDateFrom");
    this.activeDateTo = get(body, "activeDateTo");
    this.options = get(body, "options", {});
    this.repeats = get(body, "repeats");
    this.repeatsUnit = get(body, "repeatsUnit");
    this.repeatsValue = get(body, "repeatsValue");
    this.conditionals = get(body, "conditionals", []);
    this.calculations = get(body, "calculations", []);
    this.idPortalPhotoSurveyQuestions = get(
      body,
      "idPortalPhotoSurveyQuestions",
      []
    );
    this.presetValue = get(body, "presetValue");
    this.isMandatory = get(body, "isMandatory");
    this.questionImportType = get(body, "questionImportType");
    this.limitRecords = get(body, "limitRecords");

    //join props
    this.image = get(body, "image");
    this.survey_question_type = get(body, "survey_question_type");
    this.isRemember = get(body, "isRemember");
    this.isSumPS = get(body, "isSumPS");
    this.isSales = get(body, "isSales");
    this.isRequiredPortalScore = get(body, "isRequiredPortalScore", true);
    this.isRequiredPortalComment = get(body, "isRequiredPortalComment", true);
  }
}
