<template>
  <div>
    <modal
      name="modal-import-salestypes"
      :width="`90%`"
      :height="`auto`"
      scrollable
    >
      <v-snackbar v-model="snackbar" :color="snackbar_class" elevation="24">
        {{ text_snackbar }}
        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="snackbar = false">
            Close
          </v-btn>
        </template>
      </v-snackbar>
      <v-form ref="form" lazy-validation>
        <v-card>
          <v-card-text class="objective_content">
            <span class="text-h5">{{
              `${this.$t("brands.Sales")} ${this.$t("Types")}`
            }}</span>
          </v-card-text>

          <v-card-text>
            <v-row>
              <v-col cols="12" sm="12" md="12" class="text-center">
                <v-btn
                  class="mb-2 ml-2"
                  color="blue darken-1"
                  @click="downloadExcelTemplateAPI('BRAND_POS_SALES_TYPES')"
                >
                  <b-icon icon="download" aria-hidden="true"></b-icon> Plantilla
                  Import
                </v-btn>
                <v-btn
                  color="success"
                  dark
                  class="mb-2 ml-2"
                  :loading="isSelectingImport"
                  @click="onUploadExcel"
                >
                  <b-icon icon="file-excel-fill" aria-hidden="true"></b-icon>
                  {{ importButtonText }}
                </v-btn>
                <input
                  ref="uploader_file"
                  class="d-none"
                  type="file"
                  accept=".xls,.xlsx"
                  @change="onFileChangedChainChannelPerBrand"
                />
                <v-btn
                  color="warning"
                  dark
                  class="mb-2 ml-2"
                  @click="downloadexcel"
                >
                  <b-icon icon="file-excel-fill" aria-hidden="true"></b-icon>
                  Export
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <ve-table
                  id="brand_sales_types"
                  fixed-header
                  max-height="calc(100vh - 330px)"
                  :columns="tb_columns"
                  :table-data="tableData"
                  :border-around="true"
                  :border-x="true"
                  :border-y="true"
                  :sort-option="sortOption"
                  :cell-style-option="cellStyleOption"
                  :editOption="editOption"
                  rowKeyFieldName="id"
                />
                <div v-show="showEmpty" class="empty-data">Data Empty.</div>
                <div class="table-pagination mt-2 text-right">
                  <ve-pagination
                    :total="totaltableData"
                    :page-index="pageIndex"
                    :page-size="pageSize"
                    :page-size-option="pageOptions"
                    @on-page-number-change="pageNumberChange"
                    @on-page-size-change="pageSizeChange"
                  />
                </div>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click.stop="hideModal()">
              {{ $t("cancel") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </modal>
  </div>
</template>

<script>
import ApiService from "@/services/apiService";
import { logInfo, logError } from "@/utils";
import _ from "lodash";
import download from "js-file-download";
import * as XLSX from "xlsx";
import { QUESTION_TYPES } from "@/views/surveys/models";

export default {
  name: "DialogSalesTypesVetable",
  props: ["row", "salesTypes", "salesTypesList"],
  components: {},
  data: function() {
    return {
      /****** IMPORT && EXPORT ******/
      isSelectingImport: false,
      selectedFile: null,
      defaultImportbuttonText: "Import",

      /********** snackbar **********/
      snackbar: false,
      text_snackbar: "The imported file is invalid.",
      vertical: true,
      snackbar_class: "deep-purple",

      current_row: null,
      tableData: [],
      totaltableData: 0,
      /********** vue-easytable options **********/
      loadingInstance: null,
      sorting: null,
      sortOption: {
        sortChange: params => {
          this.sortChange(params);
        }
      },
      pageIndex: 1,
      pageSize: 100,
      pageOptions: [10, 50, 100],
      cellStyleOption: {
        headerCellClass: ({ column, rowIndex }) => {
          if (this.filterable_col_list.includes(column.field)) {
            return "filterable-column " + column.field;
          }
        }
      },
      editOption: {
        beforeStartCellEditing: ({ row, column, cellValue }) => {
          console.log("beforeStartCellEditing");
          console.log("row::", row);
          console.log("column::", column);
          console.log("cellValue::", cellValue);
          console.log("---");
        },
        beforeCellValueChange: ({ row, column, changeValue }) => {
          console.log("beforeCellValueChange");
          console.log("row::", row);
          console.log("column::", column);
          console.log("changeValue::", changeValue);

          console.log("---");

          if (
            ["importedObjective"].includes(column.field) &&
            !/^\d+$/.test(changeValue)
          ) {
            alert("please enter a number");
            return false;
          }
        },
        afterCellValueChange: ({ row, column, changeValue }) => {
          console.log("afterCellValueChange");
          console.log("row::", row);
          console.log("column::", column);
          console.log("changeValue::", changeValue);
          console.log("---");
          // this.saveRecord(row, column, changeValue);
        }
      },
      filterable_col_list: [],
      filterCriteria: {
        pos_name: "",
        salesType: {}
      },
      filterlist_fetch_url: "brands/questions/getfilterlist",
      showEmpty: true
    };
  },
  watch: {},
  computed: {
    importButtonText() {
      return this.isSelectingImport
        ? "Uploading..."
        : this.defaultImportbuttonText;
    },

    col_posId() {
      return {
        key: "posId",
        title: "ID POS",
        field: "posId",
        align: "left",
        width: 100,
        sortBy: ""
      };
    },
    col_pos_name() {
      return {
        key: "pos_name",
        title: "POS " + this.$t("brands.name"),
        field: "pos_name",
        align: "left",
        width: 170,
        sortBy: "",
        // renderBodyCell: ({ row, column, rowIndex }, h) => {
        //   return <span>{row?.brandName}</span>;
        // },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <v-text-field
                  value={this.filterCriteria["pos_name"]}
                  on-input={value => (this.filterCriteria["pos_name"] = value)}
                  placeholder="Search name"
                ></v-text-field>
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "pos_name")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_salesType() {
      return {
        key: "salesType",
        title: `${this.$t("brands.Sales")} ${this.$t("Types")}`,
        field: "salesType",
        align: "left",
        width: 170,
        // edit: true,
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return (
            <select
              class="vetable-edit-select"
              value={row["salesType"]}
              on-change={val => {
                row["salesType"] = val.target.value;
                this.saveRecord(row, column, val);
              }}
            >
              {this.salesTypesList.map(el => {
                return <option value={el.value}>{el.label}</option>;
              })}
            </select>
          );
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableCheckboxesOthers
                  v-model={this.filterCriteria["salesType"]}
                  dataList={this.salesTypesList}
                  filterCriteria={this.filterCriteria["salesType"]}
                  itemvalue="value"
                  itemlabel="label"
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "salesType")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_actions() {
      return {
        key: "actions",
        title: "Actions",
        field: "actions",
        align: "center",
        width: 80,
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return (
            <div>
              <v-btn color="red" on-click={() => this.remove(row.id)}>
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </div>
          );
        }
      };
    },
    tb_columns() {
      let defaultColumns = [
        this.col_posId,
        this.col_pos_name,
        this.col_salesType
      ];
      let actionCol = [this.col_actions];
      let ret = [...defaultColumns, ...actionCol];
      return ret;
    }
  },
  methods: {
    /****** MODAL CONFIGURATION ******/
    showModal(row) {
      this.init(row);
      this.$modal.show("modal-import-salestypes");
    },
    hideModal() {
      this.$modal.hide("modal-import-salestypes");
    },

    /****** IMPORT && EXPORT ******/
    onUploadExcel() {
      this.isSelectingImport = true;
      window.addEventListener(
        "focus",
        () => {
          this.isSelectingImport = false;
        },
        { once: false }
      );
      this.$refs.uploader_file.click();
    },
    async onFileChangedChainChannelPerBrand(e) {
      this.selectedFile = e.target.files[0];
      if (window.confirm(`Are you sure you want to upload this excel-file?`)) {
        this.isSelectingImport = true;
        let data = new FormData();
        data.append("file", this.selectedFile);
        this.$refs.uploader_file.value = "";
        try {
          const response = await ApiService.post(
            `brands/uploadexcelpossalestypes/${this.row.id}`,
            data,
            {
              headers: {
                "Content-Type": `multipart/form-data;`
              }
            }
          );
          const {
            success,
            insertedRowCount,
            failedRows,
            invalidFile,
            message
          } = response;
          this.snackbar_class = "deep-purple";
          if (success && insertedRowCount > 0) {
            this.text_snackbar = `${insertedRowCount} row${
              insertedRowCount > 1 ? "s" : ""
            } imported successfully`;
            this.snackbar = true;
          } else if (failedRows) {
            this.text_snackbar = `Inserted count: ${response.insertedRowCount}, Failed count: ${response.failedRows.length}`;
            this.snackbar = true;
            this.exportCSVfileGlobal(response.titles, response.failedRows);
          } else if (!invalidFile && message) {
            this.text_snackbar = message;
            this.snackbar_class = "red";
            this.snackbar = true;
          }
          await this.getDataFromApi();
          this.isSelectingImport = false;
        } catch (error) {
          console.log(error);
          this.isSelectingImport = false;
        }
      }
    },
    async downloadexcel() {
      this.show();
      const { pageIndex, pageSize, sorting, filterCriteria } = this;
      let query_params_string = this.getQueryParamsGlobal({
        pageIndex,
        pageSize,
        sorting,
        filterCriteria,
        filterableColumnsSelector: "#brand_sales_types .filterable-column"
      });
      let fetch_url = `brands/downloadexcelgetSalesTypes/${this.current_row.id}`;
      if (query_params_string !== "") {
        fetch_url += "?" + query_params_string;
      }
      const response = await ApiService.get(fetch_url, {
        responseType: "blob"
      });
      download(response, "brand_pos_sales_types.xlsx");
      this.close();
    },

    pageNumberChange(pageIndex) {
      this.pageIndex = pageIndex;
      this.getDataFromApi();
    },
    pageSizeChange(pageSize) {
      this.pageIndex = 1;
      this.pageSize = pageSize;
      this.getDataFromApi();
    },
    sortChange(params) {
      this.sorting = params;
      this.getDataFromApi();
    },
    show() {
      this.loadingInstance = this.$veLoading({
        target: document.querySelector("#brand_sales_types"),
        name: "flow" // gird, flow, wave, bounce,
      });
      this.loadingInstance.show();
    },
    close() {
      this.loadingInstance.close();
    },
    searchConfirm(closeFn) {
      closeFn();
      this.getDataFromApi();
    },
    searchCancel(closeFn, cancelFilterKey) {
      closeFn();
      if (cancelFilterKey === "pos_name") {
        this.filterCriteria[cancelFilterKey] = "";
      } else this.filterCriteria[cancelFilterKey] = {};
      this.getDataFromApi();
    },
    async getDataFromApi() {
      this.show();
      try {
        const { pageIndex, pageSize, sorting, filterCriteria } = this;
        let query_params_string = this.getQueryParamsGlobal({
          pageIndex,
          pageSize,
          sorting,
          filterCriteria,
          filterableColumnsSelector: "#brand_sales_types .filterable-column"
        });
        let fetch_url = `brands/getSalesTypes/${this.current_row.id}`;
        if (query_params_string !== "") {
          fetch_url += "?" + query_params_string;
        }
        const resp = await ApiService.get(fetch_url);
        this.tableData = resp.data;
        if (this.tableData.length > 0) {
          this.showEmpty = false;
        } else {
          this.showEmpty = true;
        }
        this.totaltableData = resp.total;
        this.close();
      } catch (error) {
        this.close();
      }
    },
    async saveRecord(row, column, changeValue) {
      if (row && column) {
        const { id, salesType } = row;
        let postData = {
          id,
          salesType
        };
        try {
          await ApiService.post(`brands/update_sales_type`, postData);
        } catch (error) {
          logError("Error occured !");
        }
      }
    },
    async remove(id) {
      if (id && window.confirm(this.$t("confirmdelete"))) {
        try {
          await ApiService.delete(`brands/remove_pos_sales_type/${id}`);
          this.getDataFromApi();
        } catch (error) {
          logError("Error occured !");
        }
      }
    },

    /****** INITIALIZE ******/
    async init(row) {
      this.current_row = row;
      this.tableData = [];
      this.totaltableData = 0;
      this.pageIndex = 1;
      this.getDataFromApi();
    }
  },
  async updated() {},
  async mounted() {
    this.filterable_col_list = Object.keys(this.filterCriteria);
  }
};
</script>

<style>
.vetable-edit-select {
  width: 100%;
  border: 1px solid #333;
  padding: 5px;
}
</style>
